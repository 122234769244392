<template>
  <div class="evo-payment-methods-wrapper">
    <div class="evo-head">
      <div class="evo-text-50 evo-mb-23">
        {{ $t('payment_methods.title') }}
      </div>
      <div class="evo-desc evo-text-33 evo-opensans-regular">
        {{ $t('payment_methods.desc') }}
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'EvoMPaymentMethodsIndexSection'
};
</script>
<style lang="scss" scoped>
.evo-payment-methods-wrapper {
  position: relative;

  .evo-head {
    background-color: $nav_color;
    background-image: url('~@/assets/payment_methods/egms-bg-mobile.png') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center;
    position: relative;
    color: $header_color;
    text-align: center;
    padding-top: 140px;
    padding-bottom: 280px;
  }

  .evo-desc {
    margin: 0 auto;
  }
}
</style>
