<template>
  <div class="evo-payment-methods-wrapper evo-ph-176 evo-pv-60">
    <div class="section-1-half-bg"></div>
    <!-- head -->
    <div class="evo-head">
      <div
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        class="evo-text-51 evo-mb-23 "
      >
        {{ $t("payment_methods.title") }}
      </div>
      <div
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="1000"
        class="evo-desc evo-text-22 evo-opensans-regular "
      >
        {{ $t("payment_methods.desc") }}
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "EvoPaymentMethodsIndexSection",
};
</script>
<style lang="scss" scoped>
.evo-payment-methods-wrapper {
  position: relative;
  background: $header_bg_color;

  .evo-head {
    position: relative;
    height: calc(60vh - 60px);
    color: $header_color;
    text-align: center;
  }

  .evo-desc {
    max-width: 590px;
    margin: 0 auto;
  }
}

.section-1-half-bg {
  background-color: $nav_color;
  background-image: url("~@/assets/payment_methods/egms-bg.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: top;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  height: 60vh;
  z-index: 0;
}
</style>
