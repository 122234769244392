<template>
  <div class="evo-payment-methods-wrapper">
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMPaymentMethodsIndexSection />
    </template>
    <template v-else>
      <EvoPaymentMethodsIndexSection />
    </template>
  </div>
</template>
<script>
import EvoPaymentMethodsIndexSection from '@/components/payment_methods_sections/index.vue';
import EvoMPaymentMethodsIndexSection from '@/mobile/components/payment_methods_sections/MIndex.vue';
export default {
  name: 'EvoPaymentMethodsIndexView',
  components: {
    EvoPaymentMethodsIndexSection,
    EvoMPaymentMethodsIndexSection
  }
};
</script>
<style lang="scss" scoped>
.evo-payment-methods-wrapper {
  position: relative;
  background: $header_bg_color;
}
</style>
